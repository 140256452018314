<template>
  <div class="product-widget-wrapper">
    <div class="product-container">
      <div class="product-container-img" @click="redirect(product.slug)">
      <img
        :src="`${uploads}/users/${sellerId}/products/200x200-conv-pn-${product.mainPhoto}`"
        alt="Product cover"
      />
      </div>
      <div class="rating-and-add-options">
<!--        <span class="rating-box">-->
<!--           <span class="rating" style="width: 65%"/>-->
<!--        </span>-->
        <div class="options" style="position: absolute; right: 20px">
          <div
            @mouseover="favouriteHover = true"
            @mouseleave="favouriteHover = false"
            @click="handleFavouriteProduct()"
          >
            <img class=""
                 :class="getImage.classes"
                 :src="getImage.image"
                 alt="favourite icon"
            >
          </div>

          <img :class="{'cart-svg svg-hover': true, 'is-added-to-cart': isAddedToCart}"
               :src="`${this.uploads}/assets/img/header/cart-icon.svg`"
               @click="addToCart(product)"
               alt="cart icon"
          >
        </div>
      </div>
      <div @click="redirect(product.slug)">
        <div class="product-name">
          <p>{{ product.name.toUpperCase() }}</p>
        </div>
        <p class="prices">
          <Price v-if="showPrices &&  product.discounts[0].percent!==0 && product.discounts[0].percent!==null"
                 :value="product.price" full="1"/>
          <Price v-if="showPrices" :value="product.discounts[0].price" discounted="1"/>
        </p>
        <div class="discount" v-if="product.discounts[0].percent!==0 && product.discounts[0].percent!==null">
          <span class="discount-percentage">- {{ product.discounts[0].percent }} %</span>
        </div>
      </div>
    </div>
    <DiscountsModal
      v-if="isDiscountModalOpen"
      :product="product"
      @discountIndex="getChosenDiscount"
      @hideModal="isDiscountModalOpen = false"
    />
  </div>
</template>

<script>
import Price from "@/components/Price.vue";
import DiscountsModal from "@/components/DiscountsModal.vue";
import {mapGetters} from "vuex";

export default {
  components: {DiscountsModal, Price},
  props: ['product', 'productType', 'showPrices', 'isMobile'],
  ...mapGetters(['favouriteProducts', 'message']),
  computed: {
    sellerId() {
      // Determine the seller ID based on the type
      // handle cases where backend returns .lean() vs non lean response
      // example Homepage Carousels vs Product Page/Categories
      if (typeof this.product.seller === 'string') {
        return this.product.seller;
      } else {
        return this.product.seller._id;
      }
    },
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
    getImage() {
      const isFavourite = this.$store.getters.favouriteProducts.find(favourite => favourite.product._id === this.product._id)

      const heartFull = `${this.uploads}/assets/img/header/favourite-icon-hover.svg`
      const heart = `${this.uploads}/assets/img/header/favourite-icon.svg`

      if(isFavourite){
        if(!this.isMobile){
          if(this.favouriteHover){
            return {
              image: heart,
              classes: 'favourite-svg favourite svg-hover-remove-to-favourite'
            };
          }else{
            return {
              image: heartFull,
              classes: 'favourite-svg favourite'
            };
          }
        }else{
          return {
            image: heartFull,
            classes: 'favourite-svg favourite'
          };
        }
      }else{
        if(!this.isMobile){
          if(!this.favouriteHover){
            return {
              image: heart,
              classes: 'favourite-svg svg-hover-add-to-favourite'
            };
          }else{
            return {
              image: heartFull,
              classes: 'favourite-svg favourite'
            };
          }
        }else{
          return {
            image: heart,
            classes: 'favourite-svg'
          };
        }
      }
    },
    isAddedToCart() {
      return  this.$store.getters.cartItems.includes(this.product._id)
    }
  },
  data() {
    return {
      favouriteHover: false,
      isDiscountModalOpen: false,
    };
  },
  watch: {},
  methods: {
    addToCart(product) {
      if (product.discounts.length === 1) {
        if (!product.active) {
          this.$snotify.error("Този продукт не е активен!");
          return;
        }
        this.$store.dispatch('addToCart', {product: product, discountIndex: 0}).then(res => {
          this.$snotify.success(res.data.message)
        })
      } else {
        this.isDiscountModalOpen = true
      }
    },
    getChosenDiscount(index) {
      this.$store.dispatch('addToCart', {product: this.product, discountIndex: index}).then(() => {
        this.isDiscountModalOpen = false
      })
    },
    redirect(slug) {
      return this.$router.push(`/products/${slug}`);
    },
    handleFavouriteProduct() {
      return this.$store.dispatch('handleFavouriteProduct', this.product).finally(() => {
        if (this.$store.getters.message) {
          this.$snotify[this.$store.getters.message.status](this.$store.getters.message.message)
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';

.product-widget-wrapper {
  width: 242px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  cursor: url('../../assets/img/cursor.png'), auto;

  .product-container {
    box-shadow: 0px 1px 14px 8px #e6e6e6;
    width: 242px;
    position: relative;

    .rating-and-add-options {
      display: flex;
      align-items: center;
      line-height: 0;
      height: 30px;
      padding: 5px 15px;
      justify-content: space-between;

      .rating-box {
        display: block;
        width: 96px;
        height: 18px;
        padding: 0px;
        position: relative;
        background: url(../../assets/img/home/star.svg) repeat-x left top;
        background-size: 19px 18px;

        .rating {
          float: left;
          height: 18px;
          background: url(../../assets/img/home/yellow-star.svg) repeat-x left top;
          background-size: 19px 18px;
        }
      }

      .options {
        display: flex;
        width: 40px;
        justify-content: flex-end;

        .favourite-svg {
          width: 20px;
          height: 20px;
          margin-top: 1px;
          filter: invert(71%) sepia(87%) saturate(2%) hue-rotate(314deg) brightness(94%) contrast(85%);
        }

        .cart-svg {
          width: 23px;
          height: 23px;
          filter: invert(71%) sepia(87%) saturate(2%) hue-rotate(314deg) brightness(94%) contrast(85%);
        }

        .svg-hover {
          &:hover {
            filter: invert(11%) sepia(92%) saturate(7215%) hue-rotate(297deg) brightness(85%) contrast(104%);
          }
        }

        .is-added-to-cart{
          filter: invert(11%) sepia(92%) saturate(7215%) hue-rotate(297deg) brightness(85%) contrast(104%);
        }
        .favourite{
          filter: invert(11%) sepia(92%) saturate(7215%) hue-rotate(297deg) brightness(85%) contrast(104%);
        }

        .svg-hover-add-to-favourite{
          &:hover {
            filter: invert(11%) sepia(92%) saturate(7215%) hue-rotate(297deg) brightness(85%) contrast(104%);
          }
        }
        .svg-hover-remove-to-favourite{
          &:hover {
            filter: invert(71%) sepia(87%) saturate(2%) hue-rotate(314deg) brightness(94%) contrast(85%);
          }
        }
      }
    }
    .product-container-img{
      width: 242px;
      height: 224px;
    }
  }

  img {
    border-radius: 5px;
    width: 242px;
    height: 224px;
  }

  .product-name {
    font-size: 20px;
    padding: 20px 13px;
    white-space: normal;
    word-wrap: break-word;
    height: 90px;

    p {
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .prices {
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
    height: 70px;
  }

  .discount {
    height: 42px;
    text-align: center;
    width: 100%;
    font-weight: 600;

    .discount-percentage {
      background-color: #b100b2;
      width: 100px;
      height: 100%;
      display: block;
      line-height: 42px;
      color: white;
      font-size: 20px;
      margin-left: calc(50% - 50px);
      border-radius: 25px 25px 0 0;
    }
  }
}

@media screen and (max-width: $xl) {
  .product-widget-wrapper {
    width: 210px;

    .product-container {
      width: 210px;
      .product-container-img{
        width: 210px;
        height: 210px;
      }
    }

    img {
      width: 210px;
      height: 210px;
    }
  }
}

@media screen and (max-width: $l) {
  .product-widget-wrapper {
    width: 250px;

    .product-container {
      width: 250px;
      .product-container-img{
        width: 250px;
        height: 250px;
      }
    }

    img {
      width: 250px;
      height: 250px;
    }
  }
}

@media screen and (max-width: $m) {
  .product-widget-wrapper {
    width: 220px;

    .product-container {
      width: 220px;
      .product-container-img{
        width: 220px;
        height: 220px;
      }
    }

    img {
      width: 220px;
      height: 220px;
    }
  }
}

@media screen and (max-width: $sm) {
  .product-widget-wrapper {
    .product-container {
      width: 220px;
    }

    img {
      width: 220px;
      height: 220px;
    }
  }
}

@media screen and (max-width: $standard) {
  .product-widget-wrapper {
    width: 180px;
    .product-container {
      width: 180px;
      .product-container-img{
        width: 180px;
        height: 180px;
      }
    }

    img {
      width: 180px;
      height: 180px;
    }

    .product-name {
      font-size: 15px;
      height: 60px;
    }
  }
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 600px) {
  .product-widget-wrapper {
    margin: 20px 10px;
    width: 160px;
    .discount {
      height: 35px;
      .discount-percentage{
        line-height: 36px;
        font-size: 16px;
      }
    }

    .product-container {
      width: 160px;
      .product-container-img{
        width: 160px;
        height: 160px;
      }
      .rating-and-add-options {
        padding: 5px 5px;
      }
    }

    img {
      width: 160px;
      height: 160px;
    }

    .product-name {
      font-size: 15px;
      height: 60px;
    }
  }
}

@media screen and (max-width: $xxs) {
}

@media screen and (max-width: 360px) {

}


</style>
